@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Merienda:wght@600&family=Lato&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins";
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(136, 135, 135);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #797878;
}

.loader-circle {
  animation: bounce 1000ms ease-out infinite;
}

.loader-circle-1 {
  animation-delay: 0ms;
}

.loader-circle-2 {
  animation-delay: 300ms; /* Adjust the delay as needed */
}

.loader-circle-3 {
  animation-delay: 600ms; /* Adjust the delay as needed */
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-2px);
  }
}

.gradient__title {
  width: 100%;
  text-align: start;
  background: -webkit-linear-gradient(right, #1c4f9c, #9b0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 15px;
}

.custom-file-input {
  width: 100%;
  overflow-x: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: -1;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: max-content !important;
  height: max-content !important;
}

.animateTable {
  animation: slideTable 0.3s ease-out;
}
@keyframes slideTable {
  0% {
    opacity: 0;
    transform: translate(0%, -10%);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0%);
  }
}

/* Tooltip */

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  margin-top: 5px;
  background-color: #a1a7f7;
  color: #111111;
  padding: 5px;
  border-radius: 3px;
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
}
